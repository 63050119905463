import { Description, Hero, Title } from './components';

import './kyc-events.scss';
import { Button, Loader } from '@storybook';
import { useRecoilValue } from 'recoil';
import { KYCEventsState } from './store/states';
import { FC, useMemo } from 'react';
import { useKycEvent } from './store/hooks';

interface IKYCEvents {}

export const KYCEvents: FC<IKYCEvents> = () => {
	const kycEventsState = useRecoilValue(KYCEventsState);
	const { onAcceptInvite, loading } = useKycEvent();

	const eventDate = useMemo(() => {
		const date = kycEventsState?.date?.split('T')[0] ?? [];
		return (
			<div className="d-flex align-center gp-8">
				<i className="ri-calendar-line"></i>
				<span className="date-info__full-datetime">{date ?? ''}</span>
			</div>
		);
	}, [kycEventsState?.date]);

	const eventTime = useMemo(() => {
		const time = kycEventsState?.date?.split('T')[1] ?? [];
		return (
			<div className="d-flex align-center gp-8">
				<i className="ri-time-line"></i>
				<span className="date-info__full-datetime">{time ?? ''}</span>
			</div>
		);
	}, [kycEventsState?.date]);

	const location = useMemo(
		() => (
			<div className="d-flex align-center gp-8">
				<div className="d-flex  align-start gp-8">
					<i className="ri-map-pin-fill"></i>
					<div>{kycEventsState?.address ?? '--'}</div>
				</div>
			</div>
		),
		[kycEventsState?.address]
	);

	return (
		<div className="kyc-events__wrapper">
			<div className="kyc-events__wrapper__inner_content">
				<Hero url={kycEventsState?.image ?? ''} />
				<div className="d-flex direction-col  align-start gp-4">
					<Title title={kycEventsState?.name ?? '--'} />
					<Description description={kycEventsState?.description ?? ''} />
				</div>
				{/* <FollowSection /> */}
				<div className="d-flex direction-col align-start gp-4">
					<Description description={eventDate} />
				</div>

				<div className="d-flex direction-col align-start gp-4">
					<Description description={eventTime} />
				</div>

				<div className="d-flex direction-col align-start gp-4">
					{/* <Title title="Location" /> */}
					<Description description={location} />
				</div>
			</div>

			<div className="kyc-events__wrapper__footer">
				<Button
					type="button__filled button__filled--primary button__large"
					label={
						loading ? (
							<Loader type="loader" dimension={20} />
						) : (
							'Accept Invitation'
						)
					}
					handleClick={onAcceptInvite}
					disabled={loading}
				/>
			</div>
		</div>
	);
};
