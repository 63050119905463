import { FC } from 'react';
import QRCode from 'react-qr-code';
import { IEventsTicket } from 'views/kyc-events/store/states';

interface ITicketBody {
	eventTicketData: IEventsTicket | null;
}

export const TicketBody: FC<ITicketBody> = ({ eventTicketData }) => {
	return (
		<div className="Event__Ticket--container_body">
			<span className="Event__Ticket--qr">
				<QRCode value={eventTicketData?.sessionId ?? ''} />
			</span>
			<div className="Event__Ticket--ticketDetails">
				<p className="Event__Ticket--ticketDetails_title">Ticket Number</p>
				<p className="Event__Ticket--ticketDetails_number">
					{eventTicketData?.ticketNumber}
				</p>
			</div>
		</div>
	);
};
