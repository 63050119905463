import { Fragment } from 'react';
import { Loader } from '@storybook';

import {
	Accreditation,
	Aml,
	BasicInformation,
	FundInvestment,
	KYB,
	KYC,
	KybForm,
	SignAgreement,
	Success,
	FaceMain,
	FormAnswering,
	CameraSettings,
	ProofReadingDocument,
	TermConditions,
	BalanceCheck,
} from 'views';
import { ComponentType } from './stores/types';
import { MainpalmEnrollment } from 'views/palm-enroll/main-palmEnrollment';
import { KYCEvents } from 'views/kyc-events';

interface IGetComponent {
	componentKey: ComponentType;
	componentProps?: any;
}
export const GetAllComponents = ({
	componentKey,
	componentProps,
}: IGetComponent) => {
	const components = {
		'term-condition': <TermConditions />,
		// 'term-condition': <MainpalmEnrollment />,
		cameraSetting: <CameraSettings {...componentProps} />,
		facialRecognition: <FaceMain />,
		palmRecognition: <MainpalmEnrollment />,
		allId: <KYC />,
		amlVerification: <Aml />,
		accreditationVerify: <Accreditation />,
		signAgreementVerification: <SignAgreement />,
		fundInvestmentVerification: <FundInvestment />,
		successScreenCompletion: <Success />,
		kybForm: <KybForm />,
		formAction: <FormAnswering />,
		dynamicForm: <FormAnswering />,
		form: <FormAnswering />,
		kybVerification: <KYB />,
		basicInformation: <BasicInformation />,
		kybFormSteps: <KybForm />,
		proofVerification: <ProofReadingDocument />,
		balanceCheck: <BalanceCheck />,
		eventVerification: <KYCEvents />,
		default: (
			<div className="loading-steps">
				<Loader />
			</div>
		),
	};
	return <Fragment>{components[componentKey ?? 'default']}</Fragment>;
};
