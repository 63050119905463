import { useMemo, useRef } from 'react';
import './event-ticket.scss';
import { Button, Loader } from '@storybook';
import generatePDF from 'react-to-pdf';
import { useRecoilValue } from 'recoil';
import {
	EventTicketData,
	EventTicketLoading,
} from 'views/kyc-events/store/states';
import { TicketHeader, TicketBody, TicketFooter } from './component';

export const EventTicket = () => {
	const eventTicketLoading = useRecoilValue(EventTicketLoading);
	const eventTicketData = useRecoilValue(EventTicketData);
	const ticketRef = useRef(null);
	const ticketHr = useMemo(() => {
		return (
			<div className="Event__Ticket--hr">
				<span className="Event__Ticket--hr-line"></span>
			</div>
		);
	}, []);

	if (eventTicketLoading) {
		return (
			<Loader
				description={
					<>
						<div className="GifLoader--container__subHeading">Please Wait</div>
						<div className="GifLoader--container__heading">
							Fetching your ticket
						</div>
					</>
				}
			/>
		);
	}

	return (
		<div className="Event__Ticket">
			<div className="Event__Ticket--header">Ticket</div>
			<div className="Event__Ticket--container">
				<div ref={ticketRef} className="Event__Ticket--container_outline">
					<TicketHeader eventTicketData={eventTicketData} />
					{ticketHr}
					<TicketBody eventTicketData={eventTicketData} />
					{ticketHr}
					<TicketFooter eventTicketData={eventTicketData} />
				</div>
			</div>
			<div className="Event__Ticket--footer">
				<Button
					label={'Download Ticket'}
					handleClick={() =>
						generatePDF(ticketRef, {
							filename: 'ticket.pdf',
							page: {
								margin: 2,
							},
						})
					}
					type="button__filled button__filled--primary button__large button__block"
				/>
			</div>
		</div>
	);
};
