import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { EventTicketData, EventTicketLoading, KYCEventsState } from './states';
import {
	useNetwork,
	useNextStep,
	useNotification,
	useTokenSession,
} from 'hooks';
import { useMemo, useState } from 'react';
import { AccessTokenState } from 'states';
import { API_TYPE, APIS } from 'constants/api';
import { SessionDetailsState } from 'hooks/use-next-step/stores';

export const useKycEvent = () => {
	const [kycEventsState, setKycEventsState] = useRecoilState(KYCEventsState);
	const setEventTicketLoading = useSetRecoilState(EventTicketLoading);
	const setEventTicketData = useSetRecoilState(EventTicketData);
	const { setSessionDetails, sessionPayloadDetail, handleNext } = useNextStep();
	const { code } = useRecoilValue(AccessTokenState);
	const { postTokenSession } = useTokenSession();
	const { get } = useNetwork();
	const sessionDetails = useRecoilValue(SessionDetailsState);
	const [loading, setLoading] = useState(false);

	const { warningNotification, errorNotification } = useNotification();

	const { qrId } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);

	const getEventTicket = async () => {
		const payload = {
			code: code,
			type: API_TYPE.EVENT_TICKET,
			payload: { eventId: kycEventsState?._id },
		};
		setEventTicketLoading(true);
		try {
			const res = await postTokenSession(payload);
			if (res?.statusCode === 200) {
				if (!res.users.length) {
					setTimeout(() => {
						getEventTicket();
					}, 2000);
				} else {
					const [date, time] = res?.date?.split('T') ?? [];
					setEventTicketData({
						...res.users[0],
						address: res.address,
						date,
						time,
					});
					setEventTicketLoading(false);
				}
			} else {
				errorNotification(res?.message ?? 'Somthing went wrong');
			}
		} catch (err) {
			setKycEventsState(null);
		}
	};

	const initKycEvent = async () => {
		try {
			const result = await get(`${APIS.EVENT}/${qrId}`);
			if (result.message === 'ok') {
				const { data } = result;
				setKycEventsState(data);
			}
		} catch (err) {
			setKycEventsState(null);
			// eslint-disable-next-line no-console
			console.log('Error fetching', err);
		}
	};
	const onAcceptInvite = async () => {
		const payload = {
			code,
			type: API_TYPE.UPDATE,
			payload: { nodeId: sessionPayloadDetail.currentAction._id },
		};
		setLoading(true);
		const res = await postTokenSession(payload);
		if (res?.statusCode === 200) {
			const eventResponse = { ...res };
			delete eventResponse?.statusCode;

			setSessionDetails(prev => ({
				...prev,
				nodes: eventResponse,
			}));

			handleNext({
				...sessionDetails,
				nodes: eventResponse,
			});
		} else {
			if (
				res?.statusCode === 0 ||
				res?.statusCode === undefined ||
				res?.statusCode === null
			) {
				warningNotification(
					'No response received. This might be due to a network problem. Please retry.'
				);
			} else {
				errorNotification('Something Went Wrong ');
			}
		}
		setLoading(false);
	};
	return {
		initKycEvent,
		onAcceptInvite,
		getEventTicket,
		loading,
	};
};
