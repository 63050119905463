import {
	AutoFillLocation,
	Button,
	CountryMobileNumber,
	DateCalender,
	Input,
	Loader,
} from '@storybook';
import {
	ChangeEvent,
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { BodyWrapper, LabelElement } from 'components';
import { SessionDetailsState } from 'hooks/use-next-step/stores';
import {
	formatDate,
	formatToMonthDayYear,
	getDateObj,
	isCompanyLinkedInURLValid,
	isValidORDateGreaterThanToday,
	isValidURL,
	validateDOB,
	validateEmail,
} from 'utils';
import { KYCAddressInfo } from 'views/kyc/stores';
import {
	BusinessVerification,
	RepresentativeDetail,
	SelectCompanyMember,
	SelectUserComapny,
	useTheKYBRequests,
} from './components';
import {
	BUSINESS_INFO_CONSTATNTS,
	KYB_FORM_STATE_LINEAR,
	KYB_MULTIPLE_REPRESENTATIVE,
} from './constants';
import { OwnerDetailsState } from './stores';
import { useKYBRequests } from './stores/hooks';
import {
	BusinessInformationState,
	IsBuisnessListFetchedState,
	IsBuisnessSearchGotTimeOutState,
	IsKybSupplimentalTrue,
	isMultipleRepresentativeState,
	KybBodyStepsState,
	KYBCompanyFlowState,
	KybProviderTypeState,
	KycAmlVerificationToggleActionState,
	MultipleRepresentativeDetailsState,
	SearchKybCompanyInputState,
	SearchKybCompanyMembersInputState,
	SelectedCompanyForKybState,
	TheKYBCompanyMemberForKycAmlVerification,
	TheKYBCompanyMemberForKycAmlVerificationListState,
	TheKYBCompanyMemberListState,
	TheKybSearchIntervalState,
	TheKybSubmitLoadingState,
} from './stores/states';
import { ExistSession } from 'views';
import { ExistingSessionsState, useExistingSession } from 'views/exist-session';

import { useNotification, useSharedVariables } from 'hooks';
import { REPRESENTATIVE_VALIDATION_MESSAGE } from './components/representative-detail/constant';
import { LoadingScreen } from './components/the-kyb/loading-screen';
import { SelectKycAmlCompanyMember } from './components/the-kyb/select-kyc-aml-member';
import { OnlyCharNumebrRegex } from 'constants/common';
import { IPhoneNumber, WebComponentMetaDataState } from 'states';
import './kyb.scss';
import { SelectSubsidiary } from './components/the-kyb/select-subsidiary';
import classNames from 'classnames';
import {
	CountryCode as CountryType,
	isValidPhoneNumber,
} from 'libphonenumber-js';
import countries from '@storybook/country-code/json/country-codes.json';

export const KYB = () => {
	//globle states
	const KYCAddressDetails = useRecoilValue(KYCAddressInfo);
	const businessInformation = useRecoilValue(BusinessInformationState);
	const theKybSubmitLoading = useRecoilValue(TheKybSubmitLoadingState);
	const kybProviderType = useRecoilValue(KybProviderTypeState);
	const selectedCompanyForKyb = useRecoilValue(SelectedCompanyForKybState);
	const representativeDetails = useRecoilValue(
		MultipleRepresentativeDetailsState
	);
	const businessCompanyMemberList = useRecoilValue(
		TheKYBCompanyMemberListState
	);
	const IsBuisnessSearchGotTimeOut = useRecoilValue(
		IsBuisnessSearchGotTimeOutState
	);

	const getSelectedKycAmlMembers = useRecoilValue(
		TheKYBCompanyMemberForKycAmlVerification
	);
	const kycAmlMemberList = useRecoilValue(
		TheKYBCompanyMemberForKycAmlVerificationListState
	);
	const resetSelectedKycAmlMembers = useResetRecoilState(
		TheKYBCompanyMemberForKycAmlVerification
	);
	const resetKycAmlMemberList = useResetRecoilState(
		TheKYBCompanyMemberForKycAmlVerificationListState
	);

	const kYBCompanyFlowStateValue = useRecoilValue(KYBCompanyFlowState);
	const kycAmlVerificationToggleData = useRecoilValue(
		KycAmlVerificationToggleActionState
	);

	const setKybSupplimental = useSetRecoilState(IsKybSupplimentalTrue);
	const setTheKybSearchInterval = useSetRecoilState(TheKybSearchIntervalState);
	const setIsBuisnessListFetched = useSetRecoilState(
		IsBuisnessListFetchedState
	);
	const resetSelectedCompanyForKybState = useResetRecoilState(
		SelectedCompanyForKybState
	);

	const [kybBodyStep, setKybBodyStep] = useRecoilState(KybBodyStepsState);
	const [ownerDetails, setOwnerDetails] = useRecoilState(OwnerDetailsState);
	const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);
	const [isExistSession, setIsExistSession] = useRecoilState(
		ExistingSessionsState
	);
	const webComponentMetaData = useRecoilValue(WebComponentMetaDataState);

	const searchKybCompanyInputValue = useRecoilValue(SearchKybCompanyInputState);
	const [
		searchKybCompanyMemberInputValue,
		setSearchKybCompanyMemberInputValue,
	] = useRecoilState(SearchKybCompanyMembersInputState);

	// hooks
	const { getExistingId } = useExistingSession();
	const { errorNotification } = useNotification();
	const {
		getSearchId,
		loading: getSearchIdLoader,
		getCompanyMemberList,
		memberLoading,
		handleGetMembersData,
	} = useTheKYBRequests();

	const resetBuisnessCompanyMemberList = useResetRecoilState(
		TheKYBCompanyMemberListState
	);

	//Gaurav: to check company and linkedin url toggle was off or on.
	const sessionDetails = useRecoilValue(SessionDetailsState);
	const { nodes } = sessionDetails ?? {};
	const { pipelineActions } = nodes ?? {};
	const { metadata } = pipelineActions?.[0] ?? [];
	const {
		kyb_company_url = true,
		kyb_linkedin_url = true,
		kyb_supplemental,
		representative_kyc_aml_verification: isKycAmlVerfication,
		kybSubsidiary: isKybForSubsidiary,
	} = metadata ?? {};

	setKybSupplimental(kyb_supplemental ?? false);

	// local state
	// Rahul Singh: make the input field hardcode.
	const [addressDetails, setAddressDetails] = useState<any>({});
	const [formExtraData, setFormExtraData] = useState<{ [key: string]: string }>(
		{}
	);

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isMultipleRepresentative, setIsMultipleRepresentative] =
		useRecoilState(isMultipleRepresentativeState);
	const {
		submitKyb,
		getKycDataForRepresentative,
		getSubsidiaryBusiness,
		handleSearchInputChange,
	} = useKYBRequests();

	const [errorMessage, setErrorMessage] = useState({
		fein: '',
		date_of_birth: '',
		phone: '',
	});
	const { onboardingType } = useSharedVariables();

	const { fromSignup = false } = useMemo(
		() => webComponentMetaData,
		[webComponentMetaData]
	);

	const handleValidation = (isValid: boolean) => {
		setIsPhoneValid(isValid);
	};
	// checking is selected company has subsidiary business
	const isSubsidiaryBusiness = useMemo(() => {
		if (
			kybBodyStep === 'select_member' &&
			isKybForSubsidiary &&
			businessInformation?.country === BUSINESS_INFO_CONSTATNTS.IsUSCountry
		) {
			return true;
		} else
			return (
				isKybForSubsidiary &&
				kybBodyStep === 'select_subsidiary' &&
				businessInformation?.country === BUSINESS_INFO_CONSTATNTS.IsUSCountry
			);
	}, [businessInformation?.country, isKybForSubsidiary, kybBodyStep]);
	useEffect(() => {
		if (getExistingId('kyb') && !fromSignup) {
			setIsExistSession(pre => ({ ...pre, kyb: true }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getExistingId]);

	useEffect(() => {
		if (onboardingType === 'complex') {
			//Gaurav: remove company and linkedin url input because toggle was off for both.
			if (!kyb_company_url && !kyb_linkedin_url) {
				KYB_MULTIPLE_REPRESENTATIVE.splice(1, 2);
				KYB_FORM_STATE_LINEAR.splice(3, 2);
			}
			//Gaurav: remove company input because toggle was off.
			if (!kyb_company_url && kyb_linkedin_url) {
				KYB_MULTIPLE_REPRESENTATIVE.splice(1, 1);
				KYB_FORM_STATE_LINEAR.splice(3, 1);
				setFormExtraData({
					linkedInUrl: 'https://www.linkedin.com/company/',
				});
			}
			//Gaurav: remove linkedin url input because toggle was off.
			if (kyb_company_url && !kyb_linkedin_url) {
				KYB_MULTIPLE_REPRESENTATIVE.splice(2, 1);
				KYB_FORM_STATE_LINEAR.splice(4, 1);
			}
		} else {
			setFormExtraData({
				linkedInUrl: 'https://www.linkedin.com/company/',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getKycDataForRepresentative();
		setAddressDetails({
			streetAddress: KYCAddressDetails.streetAddress,
			building: KYCAddressDetails.building,
			postal_code: KYCAddressDetails.zipCode,
			city: KYCAddressDetails.city,
			state: KYCAddressDetails.state,
			country: KYCAddressDetails.country,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>, key?: string) => {
			const formExtradata = ['website', 'name', 'fein', 'phone', 'linkedInUrl'];
			const { name, value } = e.target;
			const containsNumbers = /\d/.test(value);
			const digits = value?.replace(/[^0-9a-zA-Z]/g, '');
			const feinValue = value
				.replace(/[^0-9a-zA-Z/\- ]/g, '')
				.replace(/\s+/g, ' ');
			if (
				key === 'date_of_birth' &&
				(isValidORDateGreaterThanToday(value) || !validateDOB(value))
			) {
				setErrorMessage(prev => ({
					...prev,
					['date_of_birth']: isValidORDateGreaterThanToday(value)
						? REPRESENTATIVE_VALIDATION_MESSAGE.VALIDATE_DOB
						: 'Invalid date',
				}));
				return;
			}
			const regex = /^\S*$/;

			if (name === 'postal_code' && (value.length > 9 || !regex.test(value))) {
				return;
			}

			if (
				(name === 'country' || name === 'state' || name === 'city') &&
				containsNumbers
			) {
				return;
			}

			if (
				name === 'fein' &&
				digits.length <= 15 &&
				OnlyCharNumebrRegex.test(value.trim())
			) {
				setFormExtraData(prev => ({
					...prev,
					[name]: feinValue,
					tinType: 'EIN',
				}));
			}

			if (formExtradata.includes(name) && name !== 'fein') {
				setFormExtraData(prev => ({
					...prev,
					[name]: value,
				}));
			} else if (name === 'national_id_number') {
				let number = value;
				if (/[^-0-9]/g.test(number)) return;
				number = number?.replace(/[^0-9a-zA-Z]/g, '');
				number = number?.substring(0, 9);
				number = number?.replace(/(\w{3})(\w{2})(\w{4})/, '$1-$2-$3');
				setFormExtraData(prev => ({
					...prev,
					[name]: number?.replaceAll('-', ''),
				}));
			} else if (name === 'firstName' || name === 'lastName') {
				setOwnerDetails(prev => ({ ...prev, [name]: value }));
			}

			setAddressDetails((prev: any) => ({
				...prev,
				[name || (key as string)]: value,
			}));
			setErrorMessage(prev => ({
				...prev,
				[name || (key as string)]: '',
			}));

			if (name === 'fein' && !OnlyCharNumebrRegex.test(value.trim())) {
				setErrorMessage(prev => ({
					...prev,
					[name]: 'Invalid EIN/TIN',
				}));
				return;
			}

			if (
				name === 'fein' &&
				digits.length > 15 &&
				OnlyCharNumebrRegex.test(value.trim())
			) {
				setErrorMessage(prev => ({
					...prev,
					[name]: 'The maximum length for EIN/TIN must be 15 digits',
				}));
				return;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[addressDetails]
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSubmit = async () => {
		if (onboardingType === 'complex') {
			return setIsMultipleRepresentative(true);
		}
		const formExtraDataPayload = {
			...formExtraData,
			fein: formExtraData.fein?.replace(/[^0-9a-zA-Z]/g, ''),
		};
		setIsSubmitting(true);
		const payload = {
			...addressDetails,
			...formExtraDataPayload,
		};
		const { day, month, year } = payload?.date_of_birth ?? {};
		const stringDate = formatDate(year, month, day);
		await submitKyb({
			...payload,
			date_of_birth: stringDate,
			person: ownerDetails,
		});
		setIsSubmitting(false);
	};

	//Gaurav: Multiple representative submit API
	const handleRepresentativeSubmit = useCallback(async () => {
		setIsSubmitting(true);
		/**
		 * here we are removing the country code which is attached in the phone number because
		 * as per the requirements lexis nexis uses contact numbers without country code.
		 * */
		const getRepresentativeDetails = representativeDetails.map(
			(item, index) => {
				const ssnDigits = item.national_id_number?.replace(/[^0-9a-zA-Z]/g, '');
				return {
					...item,
					national_id_number: ssnDigits,
					...(isKycAmlVerfication && {
						checkKyc: {
							isFreshKycLink:
								kycAmlVerificationToggleData?.[index]?.isFreshKycLink,
							doKyc: kycAmlVerificationToggleData?.[index]?.doKyc,
						},
					}),
				};
			}
		);

		const representativeDetailsWithoutCountryCode =
			getRepresentativeDetails.map(
				({
					country_code = '',
					telephone_number = '',
					date_of_birth,
					toggle,
					...rest
				}) => {
					const { day, month, year } = date_of_birth;

					const stringDate = formatToMonthDayYear({ year, month, day });
					return {
						...rest,
						date_of_birth: stringDate,
						telephone_number: telephone_number.replace(country_code, ''),
						...(isKycAmlVerfication && { country_code }),
						toggle,
					};
				}
			);
		const isTheKyb = kYBCompanyFlowStateValue === 'select';
		const formExtraDataPayload = {
			...formExtraData,
			fein: formExtraData.fein?.replace(/[^0-9a-zA-Z]/g, ''),
		};
		await submitKyb(
			{
				...addressDetails,
				...formExtraDataPayload,
			},
			representativeDetailsWithoutCountryCode,
			isTheKyb
		);
		setIsSubmitting(false);
	}, [
		addressDetails,
		formExtraData,
		isKycAmlVerfication,
		kYBCompanyFlowStateValue,
		kycAmlVerificationToggleData,
		representativeDetails,
		submitKyb,
	]);

	const removeComma = useCallback((data: string) => {
		data = data?.replace(/^,*/, '');
		data = data?.replace(/,*$/, '');
		return data;
	}, []);

	const handleOnCompleteAutoComplete = useCallback(
		(data: any) => {
			const {
				route = '',
				village = '',
				sublocality = '',
				postal_code = '',
				country = '',
				state = '',
				city = '',
				enteredAddress,
				street_number,
			} = data ?? {};

			const address = street_number
				? `${street_number},${route}`
				: `${route},${village}`;
			//if city name and route village is null then capture entered address
			const streetAddress =
				removeComma(address) !== '' ? removeComma(address) : enteredAddress;

			const payload = {
				streetAddress: streetAddress,
				building: route + ' ' + sublocality,
				postal_code,
				city: city,
				state: state,
				country: country ?? '',
			};

			setAddressDetails((prev: any) => ({ ...prev, ...payload }));
		},
		[removeComma]
	);

	const handleOnClear = useCallback(() => {
		const payload = {
			streetAddress: '',
			building: '',
			postal_code: '',
			city: '',
			state: '',
			country: '',
		};
		setAddressDetails({ ...addressDetails, ...payload });
	}, [addressDetails]);

	const getError = useCallback((name: string, value: string | number) => {
		if (name === 'website' && value) {
			return !isValidURL(value as string);
		}
		if (name === 'linkedInUrl' && value) {
			return !isCompanyLinkedInURLValid(value as string);
		}
		return false;
	}, []);

	const getValue = useCallback(
		(name: string) => {
			if (/name|website|linkedInUrl/.test(name)) {
				return formExtraData[name] ?? '';
			} else if (name === 'firstName' || name === 'lastName') {
				return ownerDetails[name];
			} else if (name === 'date_of_birth') {
				formExtraData[name]?.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
			} else if (name === 'fein') {
				return formExtraData[name];
			}
			if (name === 'national_id_number') {
				return formExtraData[name]?.replace(
					/(\w{3})(\w{2})(\w{4})/,
					'$1-$2-$3'
				);
			} else return addressDetails[name];
		},
		[addressDetails, formExtraData, ownerDetails]
	);

	const onHandleChange = useCallback(
		({ countryCode, phone }: IPhoneNumber) => {
			if ((/^\d+$/.test(phone) || phone === '') && phone.length <= 12) {
				setFormExtraData((prevState: any) => {
					const newState = {
						...prevState,
						countryCode: countryCode,
						phone,
					};
					return newState;
				});
				if (phone.length < 8) {
					setErrorMessage(prev => ({
						...prev,
						['phone']: 'Phone should be at least 8 digits',
					}));
				} else {
					setErrorMessage(prev => ({
						...prev,
						['phone']: '',
					}));
				}
				return;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const onHandleChangeCountry = useCallback((countryCode: string | number) => {
		setFormExtraData((prevState: any) => {
			const newState = {
				...prevState,
				countryCode: countryCode,
			};
			return newState;
		});
	}, []);

	const handleChangeText = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const {
				target: { value = '', name },
			} = event;
			setAddressDetails((prev: any) => ({ ...prev, [name]: value }));
		},
		[]
	);

	const handleFeinInputLimit = useCallback((e: any) => {
		const pastedData = e?.clipboardData
			?.getData('text/plain')
			.replace(/[^0-9a-zA-Z]/g, '');

		const maxLength = 15;

		/* handling excessive length of pasted data in input */
		if (pastedData?.trim().length > maxLength) {
			setErrorMessage(prev => ({
				...prev,
				fein: `value must be ${maxLength} digits`,
			}));
			e.preventDefault();
		}

		if (
			e.target.value?.trim().replace(/[^0-9a-zA-Z]/g, '')?.length > maxLength
		) {
			e.preventDefault();
		}
	}, []);

	const getInput = useCallback(() => {
		return (
			//Gaurav: Form will change in complex kyb case.
			(
				onboardingType === 'complex'
					? KYB_MULTIPLE_REPRESENTATIVE
					: KYB_FORM_STATE_LINEAR
			)?.map((el, index: number) => {
				switch (el.type) {
					case 'auto-fill':
						return (
							<Fragment key={`${el.name}__${index.toString()}`}>
								<AutoFillLocation
									isRequired={true}
									isLocate={false}
									placeHolder={el.placeHolder}
									onSuccess={handleOnCompleteAutoComplete}
									onClear={
										addressDetails[el.name]?.length > 0
											? handleOnClear
											: undefined
									}
									handleOnChange={handleChange}
									name={el.name}
									value={addressDetails[el.name]}
									handleChangeText={handleChangeText}
									isManualSearch
								/>
							</Fragment>
						);

					case 'text':
						return (
							<Fragment key={`${el.name}__${index.toString()}`}>
								<Input
									isRequired={el.name !== 'linkedInUrl'} // Set isRequired to true for other text inputs
									label={el.label}
									inputType={el.type}
									placeholder={el.placeHolder}
									handleChange={handleChange}
									inputName={el.name}
									value={getValue(el.name)}
									isError={
										el.name !== 'linkedInUrl' &&
										getError(el.name, formExtraData[el.name] as string)
									}
								/>
							</Fragment>
						);
					case 'date':
						return (
							<div className="">
								<DateCalender
									value={getDateObj(getValue(el.name))}
									label={el.label}
									onChange={(e: any) => handleChange(e, 'date_of_birth')}
									isRequired
									errorMessage={errorMessage['date_of_birth']}
									isError={!!errorMessage['date_of_birth']}
								/>
							</div>
						);
					case 'fein':
						return (
							<div className="kyb-details__dropdown__wrapper">
								<div
									style={{
										width: '100%',
										height: '100%',
										display: el.name === 'fein' ? 'block' : 'none',
									}}
								>
									<Input
										inputType="text"
										label={`${el.label} EIN/TIN`}
										inputName={el.name}
										placeholder={'EIN/TIN' + ' number'}
										handleChange={handleChange}
										value={getValue(el.name) ?? ''}
										errorMessage={errorMessage.fein}
										isError={
											!!errorMessage.fein ||
											(el.name !== 'linkedInUrl' &&
												getError(el.name, formExtraData[el.name] as string))
										}
										isRequired={true}
										handleKeyPress={handleFeinInputLimit}
										autoComplete="off"
										onPaste={handleFeinInputLimit}
									/>
								</div>
							</div>
						);

					case 'phone':
						return (
							<CountryMobileNumber
								handleChange={onHandleChange}
								defaultCountryCode={formExtraData.countryCode}
								defaultNumber={formExtraData.phone}
								handleChangeCountry={onHandleChangeCountry}
								// disabled={appleUserDetails.phone || extractedData.phone}
								isRequired
								errorMessage={errorMessage.phone}
								isError={!!errorMessage['phone']}
								handleValidation={handleValidation}
							/>
						);

					default:
						return <></>;
				}
			})
		);
	}, [
		onboardingType,
		handleOnCompleteAutoComplete,
		addressDetails,
		handleOnClear,
		handleChange,
		handleChangeText,
		getValue,
		getError,
		formExtraData,
		errorMessage,
		onHandleChange,
		handleFeinInputLimit,
		onHandleChangeCountry,
	]);

	const isEmpty = useCallback((str: string) => {
		if (typeof str === 'string') {
			const data = str?.trim();
			if (data) return data;
		}
		return null;
	}, []);

	const isEmptyDOB = useCallback((value: object) => {
		if (value) return Object.values(value ?? {})?.some(el => !el);
		return true;
	}, []);

	const isNoErrorExist = useMemo(
		() => Object.values(errorMessage ?? {}).some(el => el),
		[errorMessage]
	);

	const isDisabled = useMemo(() => {
		const { streetAddress, country, date_of_birth, city, state, postal_code } =
			addressDetails ?? {};

		const { firstName, lastName } = ownerDetails;
		const {
			name,
			fein,
			website,
			phone,
			linkedInUrl,
			country_code = '',
		} = formExtraData ?? {};
		let isDisable = true;
		//Gaurav: In case of complex, we not fill first and last name in company form.
		if (
			onboardingType !== 'complex' &&
			isEmpty(firstName) &&
			isEmpty(lastName)
		) {
			isDisable = false;
		} else {
			isDisable = true;
		}
		//Gaurav: company url validation when its input field present.
		if (
			kyb_company_url &&
			isEmpty(website as string) &&
			isValidURL(formExtraData.website as string)
		) {
			isDisable = false;
		} else {
			isDisable = true;
		}

		//Gaurav: linkedIn url validation when its input field present.
		if (
			kyb_linkedin_url &&
			isCompanyLinkedInURLValid(linkedInUrl as string) &&
			isEmpty(linkedInUrl as string)
		) {
			isDisable = false;
		} else {
			isDisable = true;
		}

		if (typeof phone === 'string') {
			const filteredPhone = phone.replace(country_code?.toString(), '');
			if (filteredPhone.length < 8) {
				return true;
			}
		}

		if (
			isEmpty(name as string) &&
			isEmpty(fein as string) &&
			isEmpty(streetAddress) &&
			isEmpty(country) &&
			isEmpty(city) &&
			isEmpty(state) &&
			isEmpty(postal_code) &&
			isEmpty(phone as string)
		) {
			isDisable = false;
		} else {
			isDisable = true;
		}

		if (isNoErrorExist) {
			isDisable = true;
		}

		if (onboardingType !== 'complex' && isEmptyDOB(date_of_birth)) {
			return true;
		}
		return isDisable;
	}, [
		addressDetails,
		ownerDetails,
		formExtraData,
		onboardingType,
		isEmpty,
		kyb_company_url,
		kyb_linkedin_url,
		isNoErrorExist,
		isEmptyDOB,
	]);

	const renderKybBody = useMemo(() => {
		switch (kybBodyStep) {
			case 'buisness_verification':
				return <BusinessVerification />;
			case 'select_company':
				return <SelectUserComapny />;
			case 'kyb-loading-screen':
				return <LoadingScreen />;
			case 'select_member':
				return isKycAmlVerfication ? (
					<SelectKycAmlCompanyMember />
				) : (
					<SelectCompanyMember setIsPhoneValid={setIsPhoneValid} />
				);
			case 'select_subsidiary':
				return <SelectSubsidiary />;
			default:
				return getInput();
		}
	}, [kybBodyStep, isKycAmlVerfication, getInput]);

	const handleKybBodyNextButton = useMemo(() => {
		if (
			kybProviderType === 'THE_KYB' &&
			kybBodyStep === 'buisness_verification'
		) {
			if (
				businessInformation?.country === BUSINESS_INFO_CONSTATNTS.IsUSCountry
			) {
				return Object.values(businessInformation).some(value => value === '');
			} else {
				return Object.keys(businessInformation).some(
					key => key !== 'state' && businessInformation[key] === ''
				);
			}
		} else return isDisabled;
	}, [businessInformation, isDisabled, kybBodyStep, kybProviderType]);

	const handleSubmitNextButton = useCallback(() => {
		if (
			kybProviderType === 'THE_KYB' &&
			kybBodyStep === 'buisness_verification'
		) {
			getSearchId();
			setIsBuisnessListFetched(false);
			setTheKybSearchInterval(false);
		} else handleSubmit();
	}, [
		getSearchId,
		handleSubmit,
		kybBodyStep,
		kybProviderType,
		setIsBuisnessListFetched,
		setTheKybSearchInterval,
	]);

	const handleSubmitBackButton = useCallback(() => {
		setIsPhoneValid(true);
		if (kybBodyStep.length === 0 && IsBuisnessSearchGotTimeOut) {
			setKybBodyStep('buisness_verification');
			return;
		}
		return setKybBodyStep('select_company');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [IsBuisnessSearchGotTimeOut, kybBodyStep]);

	const getNextButtonLabel = useMemo(() => {
		if (
			kybProviderType === 'THE_KYB' &&
			kybBodyStep === 'buisness_verification'
		) {
			return 'Get Company Information';
		} else return 'Next';
	}, [kybBodyStep, kybProviderType]);

	// paras: handling kyc aml selected member data
	const handleKycAmlSelectedMembers = useCallback(() => {
		const { members } = kycAmlMemberList;
		const customMember = members.find(item => item.id === 'custom');

		if (customMember) {
			const {
				phone,
				email,
				national_id_number,
				date_of_birth,
				first_name,
				last_name,
			} = customMember;

			const hasAnyRequiredField = [
				first_name,
				last_name,
				national_id_number,
				phone,
				email,
				date_of_birth,
			].some(field => field);

			if (hasAnyRequiredField) {
				const isValid = [
					email,
					(phone as string)?.length > 2,
					date_of_birth,
					national_id_number,
					first_name,
					last_name,
				].every(Boolean);

				if (!isValid) return true;
			}
		}

		if (!getSelectedKycAmlMembers?.id?.length) {
			return false;
		}

		const selectedKycAmlMemberDetails = members.filter(member =>
			getSelectedKycAmlMembers?.id.includes(member?.id)
		);

		const allMembersHaveEmailAndPhone = selectedKycAmlMemberDetails.every(
			member => {
				const { phone, email, country_code } = member;
				let countryLabel: CountryType = 'US';
				const countryObj = countries.find(item => item.label === country_code);
				if (countryObj) {
					countryLabel = countryObj.code as CountryType;
				}

				const isPhoneValid =
					typeof phone === 'string' && isValidPhoneNumber(phone, countryLabel);
				return email && isPhoneValid;
			}
		);
		return !(allMembersHaveEmailAndPhone && getSelectedKycAmlMembers.isError);
	}, [getSelectedKycAmlMembers, kycAmlMemberList]);

	const handleSubmitKyb = useCallback(async () => {
		// paras: inputs error handling on submit for kyc-aml selected members
		if (handleKycAmlSelectedMembers()) {
			errorNotification('Please fill all inputs of selected members');
			return;
		}
		// Check if KYB is for a subsidiary
		if (isSubsidiaryBusiness && !handleKycAmlSelectedMembers()) {
			getSubsidiaryBusiness();
			setKybBodyStep('select_subsidiary');
			return;
		}
		setSearchKybCompanyMemberInputValue('');
		await submitKyb({}, [], true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		errorNotification,
		handleKycAmlSelectedMembers,
		isSubsidiaryBusiness,
		setKybBodyStep,
		submitKyb,
	]);

	const handleNextCompanySelect = useCallback(() => {
		if (selectedCompanyForKyb?.id === 'manual') {
			setKybBodyStep('');
			setTheKybSearchInterval(true);
			return;
		}
		if (
			kybBodyStep === 'select_company' &&
			selectedCompanyForKyb?.id !== 'manual'
		) {
			setTheKybSearchInterval(true);
			getCompanyMemberList();
		} else if (kybBodyStep === 'select_member') {
			handleSubmitKyb();
			setTheKybSearchInterval(true);
		} else return;
	}, [
		getCompanyMemberList,
		handleSubmitKyb,
		kybBodyStep,
		selectedCompanyForKyb?.id,
		setKybBodyStep,
		setTheKybSearchInterval,
	]);

	const renderNextButtonLabel = useMemo(() => {
		if (
			theKybSubmitLoading ||
			(memberLoading && kybBodyStep === 'select_company')
		) {
			return <Loader type="loader" dimension={20} />;
		} else if (isSubsidiaryBusiness) {
			return 'Next';
		} else return selectedCompanyForKyb?.id === 'manual' ? 'Next' : 'Submit';
	}, [
		theKybSubmitLoading,
		memberLoading,
		isSubsidiaryBusiness,
		selectedCompanyForKyb?.id,
		kybBodyStep,
	]);

	const handleKybBackButton = useCallback(() => {
		setSearchKybCompanyMemberInputValue('');
		if (kybBodyStep === 'select_company') {
			setKybBodyStep('buisness_verification');
			resetSelectedCompanyForKybState();
			setTheKybSearchInterval(true);
		} else if (kybBodyStep === 'select_subsidiary') {
			setKybBodyStep('select_member');
		} else if (kybBodyStep === 'select_member') {
			resetBuisnessCompanyMemberList();
			resetKycAmlMemberList();
			setKybBodyStep('select_company');
			handleGetMembersData('', true);
			setIsPhoneValid(true);
		} else {
			resetSelectedKycAmlMembers();
			resetKycAmlMemberList();
			setKybBodyStep('select_company');
		}
	}, [
		kybBodyStep,
		resetBuisnessCompanyMemberList,
		resetKycAmlMemberList,
		resetSelectedCompanyForKybState,
		resetSelectedKycAmlMembers,
		setKybBodyStep,
		setSearchKybCompanyMemberInputValue,
		setTheKybSearchInterval,
		handleGetMembersData,
	]);

	const isDisabledSubmitBtn = useMemo(() => {
		if (kybBodyStep === 'select_member') {
			if (isKycAmlVerfication) {
				return false;
			}
			const companyMemberDetails =
				businessCompanyMemberList?.members?.[
					businessCompanyMemberList.activeIndex as any
				];
			if (companyMemberDetails && companyMemberDetails.id === 'custom') {
				const {
					email = '',
					phone = '',
					date_of_birth = {},
					national_id_number = '',
					id,
					first_name = '',
					last_name = '',
					country_code = '',
				} = companyMemberDetails ?? {};
				const nameRegex = /^[a-zA-Z\s]+$/;
				if (
					(id === 'custom' &&
						(!first_name || !nameRegex.test(first_name as string))) ||
					!last_name ||
					!nameRegex.test(last_name as string)
				) {
					return true;
				}
				if (email === '' || validateEmail(email as string)) {
					return true;
				}

				if (
					(national_id_number as string)?.replace(/[^0-9a-zA-Z]/g, '')?.length >
					15
				) {
					return true;
				}
				if (typeof phone === 'string') {
					const filteredPhone = phone.replace(country_code.toString(), '');
					if (filteredPhone.length < 4) {
						return true;
					}
				}
				if (
					(phone as string)?.length > 0 &&
					Object.keys(date_of_birth).length > 0 &&
					(national_id_number as string)?.replace(/[^0-9a-zA-Z]/g, '')?.length >
						0
				) {
					return false;
				}
				if (
					date_of_birth &&
					(isValidORDateGreaterThanToday(date_of_birth) ||
						!validateDOB(date_of_birth))
				) {
					return true;
				}
				return true;
			}

			return typeof businessCompanyMemberList.activeIndex !== 'number';
		}
		return (
			Object.keys(selectedCompanyForKyb).length === 0 ||
			theKybSubmitLoading ||
			memberLoading
		);
	}, [
		businessCompanyMemberList.activeIndex,
		businessCompanyMemberList?.members,
		kybBodyStep,
		selectedCompanyForKyb,
		theKybSubmitLoading,
		isKycAmlVerfication,
		memberLoading,
	]);

	const renderMainComponent = useMemo(() => {
		const isBusinessOrSubsidiary =
			kybBodyStep === 'buisness_verification' ||
			kybBodyStep === 'select_subsidiary';
		return (
			<div
				className={classNames('kyb-details--container', {
					'kyb-responsive': !isBusinessOrSubsidiary,
				})}
			>
				<div
					className={classNames('input-fields', {
						'kyb-responsive__overflow': !isBusinessOrSubsidiary,
					})}
				>
					{renderKybBody}
				</div>

				{kybBodyStep === 'select_company' || kybBodyStep === 'select_member' ? (
					<div className="kyb-button-wrapper kyb-responsive__button-sticky">
						<Button
							label={renderNextButtonLabel}
							handleClick={() => handleNextCompanySelect()}
							type="button__filled button__filled--primary button__large the-kyb-button "
							disabled={!isPhoneValid || memberLoading || isDisabledSubmitBtn}
						></Button>
					</div>
				) : (
					<>
						{kybBodyStep !== 'kyb-loading-screen' &&
							kybBodyStep !== 'select_subsidiary' && (
								<div className="kyb__submit--button kyb-responsive__button-sticky">
									<Button
										type="button__filled button__filled--primary button__large button__block"
										label={
											isSubmitting || getSearchIdLoader ? (
												<div>
													<Loader type="circle" dimension={26} />
												</div>
											) : // Gaurav : Incase of complex Next button will appear
											onboardingType === 'complex' ? (
												getNextButtonLabel
											) : (
												'Submit'
											)
										}
										handleClick={handleSubmitNextButton}
										disabled={
											!isPhoneValid ||
											getSearchIdLoader ||
											handleKybBodyNextButton ||
											isSubmitting ||
											businessInformation.error?.error_fein?.length > 0
										}
									/>
								</div>
							)}
					</>
				)}
			</div>
		);
	}, [
		renderKybBody,
		kybBodyStep,
		memberLoading,
		renderNextButtonLabel,
		isDisabledSubmitBtn,
		isSubmitting,
		getSearchIdLoader,
		onboardingType,
		getNextButtonLabel,
		handleSubmitNextButton,
		handleKybBodyNextButton,
		businessInformation.error?.error_fein?.length,
		handleNextCompanySelect,
		isPhoneValid,
	]);

	const kybBodyHeaderElement = useMemo(() => {
		if (
			kybBodyStep !== 'kyb-loading-screen' &&
			kybBodyStep !== 'select_subsidiary'
		) {
			return (
				<div className="kyb-details__header">
					<div className="kyb-details__header__title">
						{kybBodyStep === 'select_member'
							? 'Members list'
							: '	Business/Company Details'}
					</div>
					<div className="kyb-details__header__sub-title">
						{kybBodyStep === 'select_company'
							? 'Found these results for searched business/company.'
							: kybBodyStep === 'select_member'
								? 'Please select your name from the list below and provide the additional details.'
								: 'Please provide all these details about the business/company.'}
					</div>
				</div>
			);
		} else return <></>;
	}, [kybBodyStep]);

	const renderSearchInput = () => {
		const showSearchInput =
			kybBodyStep.includes('select_company') ||
			kybBodyStep.includes('select_member');
		if (!showSearchInput) return <></>;

		const placeholder =
			kybBodyStep === 'select_member'
				? 'Search by Company Member Name or Designation'
				: 'Search by Company Name or TIN Number';

		const value =
			kybBodyStep === 'select_member'
				? searchKybCompanyMemberInputValue
				: searchKybCompanyInputValue;

		return (
			<div className="kyb-search-input__search-wrapper">
				<i className="kyb-search-input__search-wrapper__search-icon ri-search-line" />
				<input
					className="kyb-search-input__search-wrapper__search-bar"
					type="text"
					placeholder={placeholder}
					value={value}
					onChange={handleSearchInputChange}
				/>
			</div>
		);
	};

	const kybBackBtn = useMemo(() => {
		return (
			<>
				{(kybBodyStep === '' ||
					kybBodyStep === 'select_company' ||
					kybBodyStep === 'select_member' ||
					kybBodyStep === 'select_subsidiary') && (
					<Button
						type="header-back-btn"
						handleClick={
							kybBodyStep === 'select_company' ||
							kybBodyStep === 'select_member' ||
							kybBodyStep === 'select_subsidiary'
								? handleKybBackButton
								: handleSubmitBackButton
						}
						label={<i className="ri-arrow-left-line"></i>}
						disabled={
							kybBodyStep === 'select_member'
								? theKybSubmitLoading
								: memberLoading
						}
					/>
				)}
			</>
		);
	}, [
		handleSubmitBackButton,
		kybBodyStep,
		handleKybBackButton,
		theKybSubmitLoading,
		memberLoading,
	]);

	const optonalClass = useMemo(() => {
		if (['select_subsidiary'].includes(kybBodyStep)) {
			{
				return 'ppl-screen-body__overflow';
			}
		} else return '';
	}, [kybBodyStep]);

	//Gaurav: In case of complex kyb, we show 2 screens.
	return isMultipleRepresentative ? (
		<RepresentativeDetail
			handleSubmit={handleRepresentativeSubmit}
			submitLoader={isSubmitting}
			isKycAmlVerfication={isKycAmlVerfication}
		/>
	) : isExistSession?.['kyb'] ? (
		<ExistSession step="kyb" />
	) : (
		<BodyWrapper
			label={<LabelElement text="Business Verification" backBtn={kybBackBtn} />}
			headerElement={kybBodyHeaderElement}
			bodyElement={renderMainComponent}
			optionalClassNameBody={optonalClass}
			SearchInputElement={renderSearchInput()}
		/>
	);
};
