/* eslint-disable no-console */
import * as vision from '@mediapipe/tasks-vision';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	CameraFaceModalLoaded,
	IsFaceMeshLoadedState,
	cameraPermissionDisplayMessage,
	isCameraPermission,
	isRecording,
	isRetakeCameraState,
	selectedCameraLabel,
	selectedDeviceIdState,
	videoRefAtom,
} from '../states';
import { hasGetUserMedia } from 'utils/mediaUtils';
import { CameraPermisionsErrors } from '../constant';
import { boundingBoxCheck } from 'hooks/use-next-step/stores';
import { isMobileDevice } from 'utils';
import { useNextStep, useSharedVariables } from 'hooks';
// // import { GestureRecognizerClass } from 'views/palm-enroll/hooks/GestureRecognizer';
const { FaceLandmarker, FilesetResolver, DrawingUtils } = vision;

// Global variable for mobile dimension
let mobileDimension = 1000;

// Check if the device is running on Android
const isAndroid = /Android/i.test(navigator.userAgent);

export const useFace = () => {
	const faceLandmarker = useRef<any>();
	const webcamRunning = useRef(false);
	const lastVideoTime = useRef(-1);
	const results = useRef<any>();
	const canvasElement = useRef<HTMLCanvasElement>();
	const videoElement = useRef<HTMLVideoElement | null>(null);
	const videoBlendShapes = useRef<HTMLElement>();
	const cameraEnableAttemptCountRef = useRef<number>(0);
	const setFaceMeshModelLoaded = useSetRecoilState(IsFaceMeshLoadedState);

	const [mediaRecorderValue, setMediaRecorder] = useState<MediaRecorder | null>(
		null
	);
	const [, setIsRecording] = useRecoilState(isRecording);
	const [, setCameraPermission] = useRecoilState<boolean | null>(
		isCameraPermission
	);
	const [, setCameraPermissionMessage] = useRecoilState<string>(
		cameraPermissionDisplayMessage
	);
	const [isVisible, setIsVisible] = useState(true);

	const isRetakeCamera = useRecoilValue(isRetakeCameraState);
	const videoDeviceId = useRecoilValue(selectedDeviceIdState);
	const [selectedLabelvideo] = useRecoilState(selectedCameraLabel);
	const [, setBoundingBoxCheck] = useRecoilState<boolean>(boundingBoxCheck);
	const recordedChunksRef = useRef<Blob[]>([]);
	const { sessionPayloadDetail } = useNextStep();

	const apiCounterRef = useRef<number>(0);

	const { envHost } = useSharedVariables();
	const [videoRef, setVideoCameraRef] = useRecoilState(videoRefAtom);
	const setCameraFaceModalLoaded = useSetRecoilState(CameraFaceModalLoaded);

	const dataroomURL = useCallback(() => {
		if (envHost === 'stage') return 'https://api.stage.simplici.io/v1';
		if (envHost === 'preprod') return 'https://api.pp.simplici.io/v1';
		if (envHost === 'prod') return 'https://api.simplici.io/v1';
		if (envHost === 'beta') return 'https://api.beta.simplici.io/v1';
		return 'https://api.stage.simplici.io/v1';
	}, [envHost]);

	useEffect(() => {
		if (isRetakeCamera) cameraEnableAttemptCountRef.current = 0;
		return () => {
			cameraEnableAttemptCountRef.current = 0;
		};
	}, [isRetakeCamera]);

	useEffect(() => {
		return () => {
			if (videoRef instanceof HTMLVideoElement) {
				const stream = videoRef.srcObject;

				if (stream instanceof MediaStream) {
					const tracks = stream.getTracks();

					tracks.forEach(track => {
						track.stop();
					});

					videoRef.srcObject = null;
				}
			}
		};
	}, [videoRef]);

	useEffect(() => {
		const handleVisibilityChange = () => {
			setIsVisible(!document.hidden);
		};

		const requestCameraPermission = async () => {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({
					video: true,
				});
				setCameraPermission(true);
				stream.getTracks().forEach(track => track.stop());
			} catch (error) {
				setCameraPermission(false);
				console.error('Error accessing camera:', error);
				setCameraPermissionMessage(CameraPermisionsErrors.Device_in_use);
			}
		};

		// Check camera permission when component mounts
		requestCameraPermission();

		if (!document.hidden) {
			requestCameraPermission(); // Request permission when tab becomes active
		}

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, [setCameraPermission, setCameraPermissionMessage]);

	const callbackFunc = useRef<any>();

	const drawBlendShapes = useCallback((el: HTMLElement, blendShapes: any[]) => {
		if (!blendShapes.length) {
			return;
		}

		let htmlMaker = '';
		blendShapes[0].categories.forEach((shape: any) => {
			htmlMaker += `
            <li class="blend-shapes-item">
              <span class="blend-shapes-label">${
								shape.displayName || shape.categoryName
							}</span>
              <span class="blend-shapes-value" style="width: calc(${
								+shape.score * 100
							}% - 120px)">${(+shape.score).toFixed(4)}</span>
            </li>
          `;
		});

		el.innerHTML = htmlMaker;
	}, []);

	const drawCanvas = useCallback(async () => {
		if (videoElement.current) {
			const streamActive = videoElement.current.srcObject as MediaStream;
			if (streamActive) {
				const videoTracks = streamActive.getVideoTracks();
				videoTracks.forEach(track => {
					track.onended = () => {
						setCameraPermission(false);
						setCameraPermissionMessage(
							CameraPermisionsErrors.Camera_settings_OFF
						);
					};
					track.onmute = () => {
						setCameraPermission(false);
						setCameraPermissionMessage(
							CameraPermisionsErrors.Camera_settings_OFF
						);
					};
				});
			}
		}
		if (webcamRunning.current !== true) {
			return;
		}
		// document.body.style.overflow = "hidden";
		const canvasCtx = canvasElement.current?.getContext('2d');
		const canvas = canvasElement.current;
		const video = videoElement.current;

		if (!canvasCtx || !video || !canvas) {
			return;
		}

		const drawingUtils = new DrawingUtils(canvasCtx);
		const desiredVideoWidth = '100vw';

		// Calculate the video and canvas heights based on the desired width and aspect ratio
		// const videoHeight = desiredVideoWidth / videoAspectRatio;
		const screenHeight = window.innerHeight;

		const videoAspectRatio = video.videoWidth / video.videoHeight;
		let videoHeight;
		if (isAndroid) {
			// Adjust the height only for Android devices
			const screenWidth = window.innerWidth;
			videoHeight = screenWidth / videoAspectRatio + 10;
			mobileDimension = 450;
		} else {
			// For non-Android devices, use the default video height calculation
			videoHeight = screenHeight;
		}
		video.style.width = desiredVideoWidth + 'px';
		video.style.height = videoHeight + 'px';
		canvas.style.width = desiredVideoWidth + 'px';
		canvas.style.height = videoHeight + 'px';
		canvas.width = video.videoWidth;
		canvas.height = video.videoHeight;

		// Now let's start detecting the stream.
		const startTimeMs = performance.now();
		if (lastVideoTime.current !== video.currentTime) {
			lastVideoTime.current = video.currentTime;
			results.current =
				faceLandmarker.current?.detectForVideo(video, startTimeMs) ?? {};
		}

		if (results.current?.faceLandmarks?.length === 0) {
			setBoundingBoxCheck(false);
		}

		if (results.current?.faceLandmarks) {
			for (const landmarks of results.current.faceLandmarks) {
				drawingUtils.drawConnectors(
					landmarks,
					FaceLandmarker.FACE_LANDMARKS_TESSELATION,
					{ color: '#C0C0C070', lineWidth: 1 }
				);
				drawingUtils.drawConnectors(
					landmarks,
					FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
					{ color: '#C0C0C070' }
				);
				drawingUtils.drawConnectors(
					landmarks,
					FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
					{ color: '#C0C0C070' }
				);
				drawingUtils.drawConnectors(
					landmarks,
					FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
					{ color: '#C0C0C070' }
				);
				drawingUtils.drawConnectors(
					landmarks,
					FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
					{ color: '#C0C0C070' }
				);
				drawingUtils.drawConnectors(
					landmarks,
					FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
					{ color: '#C0C0C070' }
				);
				drawingUtils.drawConnectors(
					landmarks,
					FaceLandmarker.FACE_LANDMARKS_LIPS,
					{ color: '#C0C0C070' }
				);
				drawingUtils.drawConnectors(
					landmarks,
					FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
					{ color: '#C0C0C070' }
				);
				drawingUtils.drawConnectors(
					landmarks,
					FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
					{ color: '#C0C0C070' }
				);
				// Calculate the bounding box of the face
				const xs = landmarks.map((landmark: any) => landmark.x * canvas.width);
				const ys = landmarks.map((landmark: any) => landmark.y * canvas.height);
				const minX = Math.min(...xs);
				const maxX = Math.max(...xs);
				const minY = Math.min(...ys);
				const maxY = Math.max(...ys);

				// Draw the bounding box for debugging purposes
				canvasCtx.strokeStyle = 'transparent';
				canvasCtx.lineWidth = 1;
				canvasCtx.strokeRect(minX, minY, maxX - minX, maxY - minY);

				const overlayDiv = document.querySelector('.overlayDiv');
				if (overlayDiv) {
					const width = overlayDiv.clientWidth; // Get the current width of .overlayDiv
					const height = overlayDiv.clientHeight; // Get the current height of .overlayDiv
					const squareSizeWeb = Math.max(width, height); // Use the smaller dimension as the square size
					// Check if the face bounding box is inside the center square
					const squareSize = isMobileDevice() ? mobileDimension : squareSizeWeb; // Size of the square
					const centerX = canvas.width / 2 - squareSize / 2;
					const centerY = canvas.height / 2 - squareSize / 2;

					if (
						minX >= centerX &&
						maxX <= centerX + squareSize &&
						minY >= centerY &&
						maxY <= centerY + squareSize
					) {
						setBoundingBoxCheck(true);
					} else {
						setBoundingBoxCheck(false);
					}
				}
			}
		}

		callbackFunc.current?.(results.current);

		if (videoBlendShapes.current) {
			drawBlendShapes(
				videoBlendShapes.current,
				results.current?.faceBlendshapes ?? []
			);
		}
		const overlayDiv = document.querySelector('.overlayDiv');
		if (overlayDiv) {
			const width = overlayDiv.clientWidth; // Get the current width of .overlayDiv
			const height = overlayDiv.clientHeight; // Get the current height of .overlayDiv
			const squareSizeWeb = Math.max(width, height); // Use the smaller dimension as the square size

			// Draw center square
			const squareSize = isMobileDevice() ? mobileDimension : squareSizeWeb;
			// const squareSize = squareSizeWeb;
			// Size of the square
			const centerX = canvas.width / 2 - squareSize / 2;
			const centerY = canvas.height / 2 - squareSize / 2 - 30;
			canvasCtx.strokeStyle = 'transparent'; // Color of the square
			canvasCtx.lineWidth = 2; // Thickness of the square border
			canvasCtx.strokeRect(centerX, centerY, squareSize, squareSize);
		}

		// Call this function again to keep predicting when the browser is ready.
		window.requestAnimationFrame(drawCanvas);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [drawBlendShapes]);

	const updateModelFunction = useCallback(() => {
		setFaceMeshModelLoaded(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const checkCameraAvailability = useCallback(async () => {
		try {
			// Try to get access to the camera
			setCameraPermission(true);
			const stream = await navigator.mediaDevices.getUserMedia({ video: true });

			// As we have access to the camera, we should now close the stream.
			stream.getTracks().forEach(track => track.stop());
		} catch (error: any) {
			setCameraPermission(false);
			// Error handling
			if (error.name === 'NotAllowedError') {
				setCameraPermissionMessage(CameraPermisionsErrors.Not_Readable_Error);
			} else if (error.name === 'NotFoundError') {
				setCameraPermissionMessage(CameraPermisionsErrors.Camera_settings_OFF);
			} else if (error.name === 'NotReadableError') {
				setCameraPermissionMessage(CameraPermisionsErrors.Device_in_use);
			} else {
				console.log('An unknown error occurred:', error);
			}
		}
	}, [setCameraPermission, setCameraPermissionMessage]);
	useEffect(() => {
		if (isVisible) {
			checkCameraAvailability();
		}
	}, [checkCameraAvailability, isVisible]);

	const UpdateURL = useCallback(
		async (signedId: string) => {
			const signedURLsucessPayload = JSON.stringify({
				files: [
					{
						id: signedId,
						status: 'SUCCESS',
					},
				],
			});
			try {
				const response = await fetch(
					`${dataroomURL()}/dataroom/${sessionPayloadDetail.sessionId}`,
					{
						method: 'PATCH',
						headers: {
							'Content-Type': 'application/json',
						},
						body: signedURLsucessPayload,
					}
				);

				if (!response.ok) {
					console.error('Failed to upload video');
				}
			} catch (error) {
				console.error('Error uploading video:', error);
			}
		},
		[dataroomURL, sessionPayloadDetail.sessionId]
	);

	// Function to get the signed URL
	const getSignedUrl = useCallback(
		async (sessionId: string) => {
			try {
				const response = await fetch(`${dataroomURL()}/dataroom`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						sessionId: sessionId,
						files: [
							{
								extension: isMobileDevice() ? 'video/mp4' : 'video/webm',
								uploadType: 'FACE',
							},
						],
					}),
				});

				if (!response.ok) {
					console.error('Failed to fetch signed URL');
				}

				const data = await response.json();
				UpdateURL(data?.data[0]?.id);
				return data?.data[0]?.signedUrl; // Adjust based on your backend response structure
			} catch (error) {
				console.error('There was a problem with the fetch operation:', error);
			}
		},
		[UpdateURL, dataroomURL]
	);

	const uploadToSignedUrl = async (signedUrl: any, blob: any) => {
		try {
			const response = await fetch(signedUrl, {
				method: 'PUT',
				headers: {
					'Content-Type': blob.type,
				},
				body: blob,
			});

			if (!response.ok) {
				console.error('Failed to upload video');
			}
		} catch (error) {
			console.error('Error uploading video:', error);
		}
	};

	const stopRecording = useCallback(() => {
		if (mediaRecorderValue && mediaRecorderValue.state !== 'inactive') {
			mediaRecorderValue.stop();
			mediaRecorderValue.onstop = async () => {
				const recordingMimeType = isMobileDevice() ? 'video/mp4' : 'video/webm';
				const blob = new Blob(recordedChunksRef.current, {
					type: recordingMimeType,
				});

				if (apiCounterRef.current < 1) {
					const signedUrl = await getSignedUrl(sessionPayloadDetail.sessionId);
					await uploadToSignedUrl(signedUrl, blob);
					console.log('Video uploaded successfully');
				}
				apiCounterRef.current++;

				//Might need it letter let's keep this as it is
				//const reader = new FileReader();
				// reader.onloadend = () => {
				// 	// eslint-disable-next-line @typescript-eslint/no-unused-vars
				// 	const base64String = reader.result as string;
				// 	//for Testing purpose only on Stage
				// 	//eslint-disable-next-line no-console
				// 	console.log('Recording:', base64String.split(',')[1]); // Logs the base64 string without the prefix
				// };
				// reader.readAsDataURL(blob);
			};
		}
	}, [getSignedUrl, mediaRecorderValue, sessionPayloadDetail.sessionId]);

	useEffect(() => {
		return () => {
			// Clean up on unmount or route change
			stopRecording();
		};
	}, [stopRecording]);

	const startRecording = useCallback(() => {
		if (!mediaRecorderValue) return;

		if (mediaRecorderValue.state === 'recording') {
			// Stop the current recording before starting a new one
			mediaRecorderValue.stop();
			mediaRecorderValue.onstop = () => {
				// setRecordedChunks([]);
				mediaRecorderValue?.start(1000);
				// eslint-disable-next-line no-console
				console.log('MediaRecorder restarted');
			};
		} else {
			// setRecordedChunks([]);
			mediaRecorderValue.start(1000);
			// eslint-disable-next-line no-console
			console.log('MediaRecorder started');
		}
	}, [mediaRecorderValue]);

	const handleDataAvailable = useCallback((event: BlobEvent) => {
		if (event.data.size > 0) {
			recordedChunksRef.current.push(event.data);
		}
	}, []);

	const enableCam = useCallback(
		async (draw?: boolean) => {
			try {
				if (webcamRunning.current) {
					// Webcam is already running, no need to enable it again.
					return;
				}
				if (!faceLandmarker.current) {
					// eslint-disable-next-line no-console
					console.log('Wait! faceLandmarker not loaded yet.');
					return;
				}

				if (!hasGetUserMedia) {
					// eslint-disable-next-line no-console
					console.error('No camera access');
					return;
				}
				const idealSize = {
					width: { ideal: 1920 },
					height: { ideal: 1080 },
				};
				// getUsermedia parameters.
				const devices = await navigator.mediaDevices.enumerateDevices();
				let frontCamera = devices.find(
					device =>
						device.kind === 'videoinput' &&
						device.label.toLowerCase().includes('front')
				);

				if (!frontCamera) {
					// If front camera is not found, use any available video input device
					frontCamera = devices.find(device => device.kind === 'videoinput');
				}
				const videoStream = {
					video: {
						deviceId:
							videoDeviceId?.length > 0 ? videoDeviceId : frontCamera?.deviceId,
						facingMode: !selectedLabelvideo.toLowerCase().includes('front')
							? 'environment'
							: 'user', // Use "user" for front camera
						...idealSize,
					},
				};

				const streamObject =
					cameraEnableAttemptCountRef.current <= 0
						? { video: true }
						: videoStream;

				setVideoCameraRef(videoElement.current);

				// Activate the webcam stream.
				navigator.mediaDevices
					.getUserMedia(streamObject)
					.then(stream => {
						if (videoElement.current) {
							setCameraPermission(true);
							videoElement.current.srcObject = stream;
							webcamRunning.current = true;
							let options: any;
							if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
								options = { mimeType: 'video/webm; codecs=vp9' };
							} else if (MediaRecorder.isTypeSupported('video/webm')) {
								options = { mimeType: 'video/webm' };
							} else if (MediaRecorder.isTypeSupported('video/mp4')) {
								options = { mimeType: 'video/mp4', videoBitsPerSecond: 100000 };
							} else {
								console.error('no suitable mimetype found for this device');
							}
							// const mediaRecorder = new MediaRecorder(stream, options);
							const recorder = new MediaRecorder(stream, options);
							recorder.ondataavailable = handleDataAvailable;

							setIsRecording(true);
							setMediaRecorder(recorder);

							recorder.start(-1);
							if (draw) {
								videoElement.current.addEventListener('loadeddata', drawCanvas);
								videoElement.current.addEventListener(
									'loadeddata',
									updateModelFunction
								);
							}
						}
					})
					.catch(err => {
						console.error('Error in starting camera:', err);

						cameraEnableAttemptCountRef.current++;

						if (cameraEnableAttemptCountRef.current > 2) {
							setCameraPermission(false);
							if (err.name === 'NotReadableError') {
								// Handling hardware camera issue here
								setCameraPermissionMessage(
									CameraPermisionsErrors.Not_Readable_Error
								);
								// Handle 'Device in use' error
							} else if (err.message === 'Device in use') {
								// Handling device in  use case
								setCameraPermissionMessage(
									CameraPermisionsErrors.Device_in_use
								);
							} else {
								setCameraPermissionMessage(
									CameraPermisionsErrors.Camera_settings_OFF
								);
							}
						} else {
							enableCam(true);
						}
					});
			} catch (error) {
				// Handle error
				console.error('Error accessing webcam:', error);
			}
		},
		[
			drawCanvas,
			handleDataAvailable,
			selectedLabelvideo,
			setCameraPermission,
			setCameraPermissionMessage,
			setIsRecording,
			setVideoCameraRef,
			updateModelFunction,
			videoDeviceId,
		]
	);

	// Before we can use HandLandmarker class we must wait for it to finish
	// loading. Machine Learning models can be large and take a moment to
	// get everything needed to run.
	const start = useCallback(
		async ({
			video,
			canvas,
			blendShapes,
			draw = true,
			onResults,
		}: {
			video: HTMLVideoElement;
			canvas: HTMLCanvasElement;
			blendShapes?: HTMLElement | null;
			draw?: boolean;
			onResults?: (results?: any) => void;
		}) => {
			// if (!GestureRecognizerClass.FaceModel) {
			// 	return;
			// }
			try {
				videoElement.current = video;
				canvasElement.current = canvas;
				if (blendShapes) {
					videoBlendShapes.current = blendShapes;
				}

				callbackFunc.current = onResults;

				// faceLandmarker.current = GestureRecognizerClass.FaceModel;
				const filesetResolver = await FilesetResolver.forVisionTasks(
					'https://storage.googleapis.com/satschel-assets-public/%40mediapipe/tasks-vision%400.10.3/wasm'
				);

				faceLandmarker.current = await FaceLandmarker.createFromOptions(
					filesetResolver,
					{
						baseOptions: {
							modelAssetPath: `https://storage.googleapis.com/satschel-assets-public/%40mediapipe/face_landmarker.task`,
							delegate: 'GPU',
						},
						outputFaceBlendshapes: true,
						runningMode: 'VIDEO',
						numFaces: 1,
					}
				);
				enableCam(draw);
			} catch (error) {
				setCameraFaceModalLoaded(true);
			}
		},
		[enableCam, setCameraFaceModalLoaded]
	);

	useEffect(() => {
		if (mediaRecorderValue) {
			startRecording();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mediaRecorderValue]);

	const draw = useCallback(async () => {
		await drawCanvas();
	}, [drawCanvas]);
	useEffect(() => {
		const stopCamera = () => {
			const video = videoElement.current;
			const stream = video?.srcObject;
			if (stream && stream instanceof MediaStream) {
				stream.getTracks().forEach(track => track.stop());
			}
			webcamRunning.current = false;
		};

		const restartCamera = async () => {
			stopCamera();
			await enableCam();
		};

		restartCamera();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videoDeviceId, selectedLabelvideo]);
	return { start, draw };
};
