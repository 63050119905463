import { FC } from 'react';
import { IEventsTicket } from 'views/kyc-events/store/states';

interface ITicketFooter {
	eventTicketData: IEventsTicket | null;
}

export const TicketFooter: FC<ITicketFooter> = ({ eventTicketData }) => {
	return (
		<div className="Event__Ticket--container_footer">
			<div className="Event__Ticket--container_address">
				<span>
					<i className="ri-map-pin-line"></i>
				</span>
				<div>
					<p>{eventTicketData?.address}</p>
					{/* <p>
									70 Washington Square South, New York, NY 10012, United States
								</p> */}
				</div>
			</div>
			<div className="Event__Ticket--container_seatDetails">
				{/* <div>
								<span>Section/Aisle</span>
								<p>FLR 2</p>
							</div> */}
				<div>
					<span>Row</span>
					<p>{eventTicketData?.metadata.row}</p>
				</div>
				<div>
					<span>Seat</span>
					<p>{eventTicketData?.metadata.seat}</p>
				</div>
			</div>
		</div>
	);
};
