import { FC } from 'react';
import { IEventsTicket } from 'views/kyc-events/store/states';

interface ITicketHeader {
	eventTicketData: IEventsTicket | null;
}

export const TicketHeader: FC<ITicketHeader> = ({ eventTicketData }) => {
	return (
		<div className="Event__Ticket--container_header">
			<p className="Event__Ticket--container_eventName">
				{eventTicketData?.name}
			</p>
			<p className="Event__Ticket--container_eventDetails">
				<span>
					<i className="ri-calendar-todo-line"></i>
					{eventTicketData?.date}
				</span>
				<span className="Event__Ticket--circle-fill"></span>
				<span>
					<i className="ri-time-line"></i>
					{eventTicketData?.time}
				</span>
			</p>
		</div>
	);
};
