import { Button, Image, SkeltonLoader } from '@storybook';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useWindowWidth } from 'hooks';
import {
	IFormProperties,
	IDocumentVerification,
	IRenderObject,
	SessionStepProperties,
} from 'states';

import { BetterPhotoTips, BodyWrapper } from 'components';
import { IsKycLoading, useKycRequests } from 'views/kyc/stores';
import {
	formPropertiesState,
	kycActiveStepName,
	KycDocIsGlare,
	KycIsImageSubmit,
	kycIsLiveness,
	kycRenderedObj,
	SelectedKycDocType,
} from '../../store/states';
import { UseVerification } from '../../hooks/use-verification';
import { KYC_DOC_UPLOAD_PROPS } from 'views/kyc/constants';
import { KycDocumentUpload } from '../kyc-document-upload';
import { GlareDetectedScreen } from '../glare-detected-screen';
import { KycDocUploadFooter } from '../kyc-doc-upload-footer';

export const DocumentVerification: FC<IDocumentVerification> = ({
	bodyName,
	setBodyName,
}) => {
	const [renderedObj, setRenderedObj] =
		useRecoilState<IRenderObject>(kycRenderedObj);
	const [isImageSubmit, setImageSubmit] = useRecoilState(KycIsImageSubmit);

	const [imageLoading, setImageLoading] = useState(true);

	const { isMobile } = useWindowWidth();
	const isLoading = useRecoilValue(IsKycLoading);
	const { docType } = useKycRequests();
	const { methods, states } = UseVerification(bodyName);

	const [activeStepName, setActiveStepName] = useRecoilState(kycActiveStepName);
	const setIsLiveness = useSetRecoilState(kycIsLiveness);
	const formProperties = useRecoilValue<IFormProperties>(formPropertiesState);
	const sessionStepProperties = useRecoilValue(SessionStepProperties);
	const isGlare = useRecoilValue(KycDocIsGlare);
	const selectedkycDocType = useRecoilValue(SelectedKycDocType);

	const { currentAction } = useMemo(
		() => sessionStepProperties ?? {},
		[sessionStepProperties]
	);
	const { title, label, subtitle, settings } = useMemo(
		() => renderedObj ?? {},
		[renderedObj]
	);

	const { glareProps, handleBackBtn } = methods;
	const { verificationContent, cameraScreenActive } = states;
	// const { setImageSubmit } = setStates;
	const { blankImage } = verificationContent;
	const { liveness } = currentAction?.metadata ?? {};

	useEffect(() => {
		const currentStep = Object.keys(verificationContent)[1];
		if (currentStep) {
			setActiveStepName(currentStep);
			setRenderedObj(verificationContent[currentStep]);
		}
		if (liveness) {
			setIsLiveness(liveness);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [liveness, verificationContent]);

	useEffect(() => {
		setRenderedObj(verificationContent[activeStepName]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeStepName, verificationContent]);

	useEffect(() => {
		const breadcrumb = document.getElementById('breadcrumb');
		if (breadcrumb) {
			if (cameraScreenActive) {
				breadcrumb.style.visibility = 'hidden';
			} else {
				breadcrumb.style.visibility = 'visible';
			}
		}
	}, [cameraScreenActive]);

	useEffect(() => {
		setTimeout(() => {
			if (formProperties.frontImage) {
				setImageSubmit(true);
			}
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formProperties, isImageSubmit]);

	const cardContent = useMemo(() => {
		const { getBlankImage, ...props } = KYC_DOC_UPLOAD_PROPS;
		const kownDocType =
			selectedkycDocType?.name === 'Driving Licence' ||
			selectedkycDocType?.name === 'Passport';
		const activeStepProps = (props as any)[activeStepName];
		switch (activeStepName) {
			case 'step1':
				return (
					<Fragment>
						{imageLoading && <SkeltonLoader width="100%" height="220px" />}
						<Image
							onLoad={() => setImageLoading(false)}
							fileName={blankImage}
							width="100%"
							height="100%"
							className={imageLoading ? 'd-none' : 'd-block animate-in-2'}
						/>
					</Fragment>
				);

			case 'step2':
			case 'step3':
				return (
					<KycDocumentUpload
						imgSrc={(formProperties as any)[activeStepProps?.keyName]}
						{...activeStepProps}
						blankImage={getBlankImage(docType, activeStepName, kownDocType)}
						bodyName={bodyName}
					/>
				);
		}
		return <></>;
	}, [
		selectedkycDocType?.name,
		activeStepName,
		imageLoading,
		blankImage,
		formProperties,
		docType,
		bodyName,
	]);

	const bodyContent = useMemo(() => {
		return (
			<div className="ppl-screen-body__body__card-wrapper">
				{cardContent}
				{isMobile && <BetterPhotoTips />}
			</div>
		);
	}, [cardContent, isMobile]);

	const backButton = useMemo(() => {
		const backSettings = settings?.filter(({ label }) => label === 'Back');
		return backSettings && backSettings.length > 0
			? backSettings.map(({ step }) => (
					<Button
						key={`Back_${step}`}
						label={<i className="ri-arrow-left-line" />}
						handleClick={() => handleBackBtn(step, isGlare, setBodyName)}
						type={`${!cameraScreenActive ? 'ppl-screen-body__body__button-wrapper__back-button' : ''}`}
						disabled={isLoading}
					/>
				))
			: null;
	}, [
		settings,
		cameraScreenActive,
		isLoading,
		handleBackBtn,
		isGlare,
		setBodyName,
	]);

	const labelElement = useMemo(() => {
		return (
			<div className="ppl-screen-body__label-container">
				{backButton}
				<div className="ppl-screen-body__label-container__text">
					{selectedkycDocType?.name}
					{label}
				</div>
			</div>
		);
	}, [backButton, selectedkycDocType?.name, label]);

	const headerElement = useMemo(() => {
		return (
			<div className="ppl-screen-body__body__title-wrapper">
				<div className="ppl-screen-body__body__title-wrapper__title">
					{title}
					{(selectedkycDocType?.name as string).toLowerCase()}.
				</div>
				<div className="ppl-screen-body__body__title-wrapper__subtitle verification__wrapper__subtitle">
					{subtitle}
				</div>
				{!isMobile && <BetterPhotoTips />}
			</div>
		);
	}, [selectedkycDocType?.name, title, subtitle, isMobile]);

	const bodyContents = useMemo(() => {
		return (
			<div className="kyc-body-wrapper">
				<div className="kyc-body-wrapper__inner">
					<div className="kyc-body-wrapper__left">
						{isGlare ? <></> :headerElement}
			     	</div>
					<div className="kyc-body-wrapper__right">	
					{bodyContent}			
					</div>		 
				</div>
			</div>
		);
	}, [headerElement, isGlare,bodyContent]);


	return (
		<div className="verification__body_wrapper">
			<BodyWrapper
				optionalClassName="terms-body-wrapper"
				label={isGlare ? <></> : labelElement}
				bodyElement={
					isGlare ? (
						<GlareDetectedScreen
							{...glareProps()}
							labelElement={labelElement}
						/>
					) : (
						bodyContents
					)
				}
				footerElement={
					isGlare ? (
						<></>
					) : !cameraScreenActive ? (
						<KycDocUploadFooter bodyName={bodyName} />
					) : (
						<></>
					)
				}
			/>
		</div>
	);
};
