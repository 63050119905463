import { atom } from 'recoil';
import { v4 } from 'uuid';

export interface IEventsState {
	name: string;
	image: string;
	date: string;
	description: string;
	businessId?: string;
	qrId: string[];
	_id?: string;
	address: string;
	users: string[];
}

interface Metadata {
	seat: number;
	row: number;
}

export interface IEventsTicket {
	status: string;
	tags: string[];
	name: string;
	phone: string;
	email: string;
	ticketNumber: string;
	metadata: Metadata;
	image: string;
	enrolledAt: string; // ISO 8601 date string
	attendedAt: string | null; // Nullable if not attended
	changedAt: string | null; // Nullable if not changed
	sessionId: string;
	_id?: string;
	address: string;
	date: string;
	time: string;
}

export const KYCEventsState = atom<IEventsState | null>({
	key: v4(),
	default: null,
});

export const EventTicketLoading = atom<boolean>({
	key: v4(),
	default: false,
});

export const EventTicketData = atom<IEventsTicket | null>({
	key: v4(),
	default: null,
});
